 <mat-sidenav #sidenav position="end" class="example-sidenav sideNavRt" mode="side" opened="true">  
      <mat-nav-list>
        <mat-list-item>
          <button mat-icon-button (click)="isExpanded = !isExpanded">
            <mat-icon *ngIf="!isExpanded">chevron_left</mat-icon>
            <mat-icon *ngIf="isExpanded">chevron_right</mat-icon>
          </button>
        </mat-list-item>
         <!-- <mat-icon mat-list-icon>home</mat-icon> -->
          <mat-tab-group class="tabDiv" *ngIf="isExpanded">
            <mat-tab label="Latest Activity">
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">thumb_up</mat-icon>
                Latest Activity
              </ng-template>
              <p class="dtDiv">Today, 2 June 2021, Wednesday</p>
              <mat-list role="list">
                <mat-list-item role="listitem">
                  <div class="imgDiv1"><mat-icon>account_circle</mat-icon></div>
                  <p><b>Lorem Ipsum is simply</b> dummy text of the printing and typesetting industry.</p>
                </mat-list-item>
                <mat-list-item role="listitem">
                  <div class="imgDiv1"><mat-icon>account_circle</mat-icon></div>
                  <p><b>Lorem Ipsum is simply</b> dummy text of the printing and typesetting industry.</p>
                </mat-list-item>
                <mat-list-item role="listitem">
                  <div class="imgDiv1"><mat-icon>account_circle</mat-icon></div>
                  <p><b>Lorem Ipsum is simply</b> dummy text of the printing and typesetting industry.</p>
                </mat-list-item>
              </mat-list>
            </mat-tab>
            <mat-tab label="Chat"> 
              <ng-template mat-tab-label>
                <mat-icon> chat</mat-icon>
                Chat
              </ng-template>
                
            </mat-tab>
            <mat-tab label="Notes">
              <ng-template mat-tab-label>
                <mat-icon>note</mat-icon>
                Notes
              </ng-template>
              

            </mat-tab>
          </mat-tab-group>
      </mat-nav-list>
    </mat-sidenav>
  