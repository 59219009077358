import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login-service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { FormControl, ValidationErrors } from '@angular/forms';

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const passwordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*?_-])[A-Za-z0-9\d!@#$%^&*?_-]{8,15}$/
);

export function EmailValidator(control: FormControl): ValidationErrors {
  if (control.value && !emailRegex.test(control.value)) {
    return {
      email: true
    };
  }
  return null;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  rememberMe = null;
  isUserLoggedIn= false;
  Fields: any = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email',
        placeholder: 'Enter your email address',
        required: true
      },
      validators: {
        email: {
          expression: c => emailRegex.test(c.value),
          message: (error, field: any) => 'Enter a valid email address'
        }
      },
      validation: {
        messages: {
          required: 'Enter a valid email address'
        }
      },
      hooks: {
        onInit: field => {
          const control = field.formControl;
          if (this.checkIsRemeberMe()) {
            const email = localStorage.getItem('email');
            control.setValue(email);
          } else {
            control.setValue(null);
          }
        }
      }
    },
    {
      key: 'password',
      type: 'input',
      templateOptions: {
        type: 'password',
        label: 'Password',
        placeholder: 'Enter your password',
        required: true
      },
      validators: {
        password: {
          expression: c => passwordRegex.test(c.value),
          message: (error, field: any) => `Enter a valid password`
        }
      },
      validation: {
        messages: {
          required: 'Enter a valid password'
        }
      }
    }
  ];

  model: any = {};
  loggedIn: boolean;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
   
    if (this.checkIsRemeberMe()) {
      const rememberme = localStorage.getItem('rememberme');
      this.rememberMe = true;
    } else {
      this.rememberMe = false;
    }
  }

  items = [
    {
      "img": "../../../assets/img/1.jpg",
      "alt": "",
      "smallText": "Online Classes",
      "longText": "Connect From Home or anywhere."
    },
    {
      "img": "../../../assets/img/2.jpg",
      "alt": "",
      "smallText": "second Slide",
      "longText": "Long Description should be placed over here."
    },
    {
      "img": "../../../assets/img/3.jpg",
      "alt": "",
      "smallText": "third Slide",
      "longText": "Long Description should be placed over here."
    },
    {
      "img": "../../../assets/img/4.jpg",
      "alt": "",
      "smallText": "fourth Slide",
      "longText": "Long Description should be placed over here."
    }
  ];
  checkIsRemeberMe(): boolean {
    const remember = JSON.parse(localStorage.getItem('rememberme')) || null;
    if (remember === true || remember === 'true') {
      return true;
    } else {
      return false;
    }
  }

  forgotPassword(): void {
    this.router.navigateByUrl('/forgotpassword');
  }

  register(): void {
    this.router.navigateByUrl('/register');
  }

  formSubmit(model): void {
    const user: User = {
      email: model.email,
      password: model.password,
      clientId: 'F1',
      phone:''
    };
    if (this.rememberMe === true || this.rememberMe === 'true') {
      localStorage.setItem('email', model.email);
      localStorage.setItem('rememberme', this.rememberMe);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('rememberme');
    }
    this.loginService.login<User>(user).subscribe(
      data => {
       this.router.navigateByUrl('/dashboard');
      },
      error => {
        if (error === null) {
          this.toaster.error('Something went wrong. Check with administrator.');
        } else if (
          error !== null &&
          (error.message === null || error.message === undefined)
        ) {
          if (error == 'Invalid credentials.') {
            this.toaster.error(
              'Wrong password. Try again or click ‘Forgot password’ to get the updated one.'
            );
          } else {
            this.toaster.error(error);
          }
        } else {
          this.toaster.error(error.message);
        }
      },
      () => {}
    );
  }
}
