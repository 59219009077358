<div class="dashboardPage wrapper">
<div class="row">
    <div class="col-md-1">test</div>
   
    <div class="col-md-9">
        <div class="titleDiv">
            <h2>Application Details</h2>
        </div>
       
    <div class="example-container mat-elevation-z8">

            <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
              </ng-container>
            
          
           <ng-container matColumnDef="birthdate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> BirthDate </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.birthdate}} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.email}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button class="btn btn-dark"  (click)="sendNotificationToWf('approve')">Approve</button>
                <div style="padding-left: 5px;"></div>
                <button class="btn btn-danger" (click)="sendNotificationToWf('reject')" >Reject</button>
               
              </mat-cell>
            </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    </div>
</div>
</div>



</div>