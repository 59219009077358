import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoginService } from '../services/login-service';
import { AuthService } from '../services/auth.service';
import { LoaderService } from '../components/custom-loader/loader.service';
import { ToasterService } from '../services/toaster.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(
    private authservice: AuthService,
    private loginservice: LoginService,
    private loaderService: LoaderService,
    private toaster: ToasterService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.showLoader();

    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if (event && event.status) {
            this.hideLoader();
          }
        }

        return event;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          this.hideLoader();
          // if (err.status !== 401) {
          //   return;
          // }
          if (err.status === 401) {
            // auto logout if 401 response returned from api
            // it means session is closed from server
            const expired = this.authservice.isSesionExpired();
            if (expired) {
              const ret = this.loginservice.logout();
              if (ret) {
                this.toaster.warning(
                  'Your session is expired. please login again.'
                );
              }
            }
          }
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
      })
    );

    /* return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          if (ev.status === 200) {
            this.hideLoader();
          }
        }
        const expired = this.authservice.isSesionExpired();
        if (expired) {
          const ret = this.loginservice.logout();
          if (ret) {
            this.toaster.warning(
              'Your session is expired. please login again.'
            );
          }
        }
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          this.hideLoader();
          if (err.status === 401) {
            // auto logout if 401 response returned from api
            // it means session is closed from server
            const ret = this.loginservice.logout();
            if (ret) {
              this.toaster.warning(
                'Your session is expired. please login again.'
              );
            }
          }
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
      })
    ); */
  }

  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
