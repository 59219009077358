import { Component, ViewChild,OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {NavItem} from './nav-item';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {
  subscription: Subscription;
  isUserLoggedIn: any;
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  isAdmin = false;
  showSubSubMenu: boolean = false;
  navItems: NavItem[];
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  constructor( private authService: AuthService, private toaster: ToasterService,private router: Router) {
    this.subscription = this.authService
      .getLoggedInUser()
      .subscribe(isLoggedIn => {
        this.isUserLoggedIn = isLoggedIn;
      });
      this.isAdmin = this.authService.isLoggedInUserAdmin;
   }

  ngOnInit(): void {
    const userdata = JSON.parse(localStorage.getItem('loggedInUser'));
   
      this.navItems = [
        {
          displayName: 'Application Form',
          iconName: 'forms',
          route:"/forms",
          isPublic:true,
          
         
        },
        {
          displayName: 'Form Listing',
          iconName: 'grid',
          route:"/programs",
          isPublic:false,
         
         
        },
       
        
        
      ];
      
 
    
    
  }
  logoutUser(e): void {
    this.authService.clearAllUserSessionStorages();
    this.router.navigateByUrl('/login');
  }
  // gotoPrograms(e): void {
  //   this.router.navigateByUrl('/programs'); 
  // }
  // functionalityToBeImplemented(){
  //   this.toaster.error("Page is yet to be implemented");
  // }

}
