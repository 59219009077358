import { BrowserModule } from '@angular/platform-browser';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupModule } from 'f1-signup-form-angular';
import { SigninModule } from 'f1-singin-form-angular';
import { MaterialModule } from './material.module';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
/*
 To activate custom constants based theme
*/
import { darkTheme, lightTheme, ThemeModule } from './theme';
/* Components */
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HeaderComponent } from './components/header/header.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { LoaderComponent } from './components/custom-loader/loader.component';
import { ToasterService } from './services/toaster.service';
import { ConfigurationService } from './services/configuration.service';
import { HttpAuthInterceptor } from './helpers/httpauth.interceptor';
import { ForgotpasswordModule } from 'f1-forgotpassword-angular';
import { ChatbotModule } from 'f1-chatbot-angular';
import {NavHeaderModule} from 'f1-navigation-header-angular';
import { FooterModule } from "f1-footer-angular";

//  import {​​​​​ DatagridModule }​​​​​ from "f1-data-grid-angular";
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS
} from '@angular/material/form-field';

import { SearchSortPipe } from './pipes/search-sort.pipe';
import { DashboardComponent } from './components/dashboard/dashboard.component';
// import { DynamicJsonGenericFormModule } from 'f1-dynamic-form-angular';
// import { CountdownModule } from 'ngx-countdown';
import { CanDeactivateGuard } from './services/deactivate-guard';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { ConfirmSessionDialogService } from './services/dialog-session.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { CommonEndpointService } from './services/common-endpoint.service';
// import { NgHttpLoaderModule } from 'ng-http-loader';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { RightSideNavigationComponent } from './components/right-side-navigation/right-side-navigation.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProgramDetailsComponent } from './components/program-details/program-details.component';
import { SubSideNavComponent } from './components/sub-side-nav/sub-side-nav.component';
import { PageUnderConstructionComponent } from './components/page-under-construction/page-under-construction.component';
import { RatingsComponent } from './components/ratings/ratings.component';
import { FormGenericComponent } from './components/form-generic/form-generic.component';
import { HelpComponent } from './components/help/help.component';
import { UserLibraryComponent } from './components/user-library/user-library.component';
import { FormsComponent } from './components/forms/forms.component';
import { ChartsModule } from 'ng2-charts';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
import { DynamicJsonFormModule } from 'f1-dynamic-stepper-form-angular';
import { DynamicJsonGenericFormModule } from 'f1-dynamic-form-angular';
import { CarouselModule } from 'f1-carousel-angular';
const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    NavHeaderModule,
    FooterModule,
    
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    CarouselModule,
    SigninModule,
    ForgotpasswordModule,
    SignupModule,
    ChatbotModule,
    CommonModule,
    DynamicJsonFormModule,
    DynamicJsonGenericFormModule,
    BrowserAnimationsModule,
   
    ThemeModule.forRoot({
      themes: [lightTheme, darkTheme],
      active: 'light'
    })
  ],
  declarations: [
    SearchSortPipe,
    AppComponent,
    LoaderComponent,
    LoginComponent,
    RegisterComponent,
    SessionTimeoutDialogComponent,
    ForgotpasswordComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    SideNavigationComponent,
    MenuListItemComponent,
    RightSideNavigationComponent,
    ProgramsComponent,
    ProgramDetailsComponent,
    SubSideNavComponent,
    PageUnderConstructionComponent,
    RatingsComponent,
    FormGenericComponent,
    HelpComponent,
    UserLibraryComponent,
    FormsComponent,
    DialogBoxComponent,
    
    
    
  ],
  providers: [
    HttpClient,
    CanDeactivateGuard,
    ToasterService,
    EndpointFactory,
    CommonEndpointService,
    ConfigurationService,
    ConfirmSessionDialogService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
