import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login-service';
import { ToasterService } from 'src/app/services/toaster.service';
import { User } from 'src/app/models/user.model';
import * as countryCodes from './country_code.json';

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const passwordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*?_-])[A-Za-z0-9\d!@#$%^&*?_-]{8,15}$/
);

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
  registerForm: any;
  title = 'Register';

  Fields: any = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Name',
        placeholder: 'Enter Your Name *',
        maxLength: 50,
        required: true,
        change: (field, $event) => {
          if ((field.formControl.value).trim()==='') {
            field.formControl.setErrors({
              serverError: { message: 'Enter first name and surname' }
            });
            return false;
          }
        }
      },
      validators: {
        name: {
          expression: c => /^[a-zA-Z0-9 ]{2,50}$/.test(c.value),
          message: (error, field: any) =>
            // `"${field.formControl.value}" is not a valid email address`,
            
            {
              if (!field.formControl.value.replace(/\s/g, '').length) {
               return 'Only whitespace not allowed'
              }
              else
              return 'Enter first name and surname'
            }
            
        }
      },
      validation: {
        messages: {
          required: 'Enter first name and surname'
        }
      }
    },
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email',
        placeholder: 'Enter your email Address',
        required: true
      },
      validators: {
        email: {
          expression: c => emailRegex.test(c.value),
          message: (error, field: any) =>
            // `"${field.formControl.value}" is not a valid email address`,
            'Enter a valid email address'
        }
      },
      validation: {
        messages: {
          required: 'Enter a valid email address'
        }
      }
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'countrycode',
          defaultValue: '+1',
          className: 'col-sm-4 countrycode',
          type: 'select',
          templateOptions: {
            options: countryCodes['default'],
            labelProp: o => {
              return o.label + ' ( ' + o.value + ' ) ';
            },
            label: 'Country Code',
            placeholder: 'Enter your country code',
            required: true
          }
        },
        {
          key: 'phone',
          type: 'input',
          className: 'col-sm-8 phonenumber',
          // defaultValue: '+1',
          templateOptions: {
            label: 'Phone',
            placeholder: 'Enter your phone number',
            required: true
          },
          validators: {
            email: {
              expression: c => /^[- +()0-9]{10}$/.test(c.value),
              message: (error, field: any) =>
                // `"${field.formControl.value}" is not a valid email address`,
                'Enter valid "10" digit  number'
            }
          },
          validation: {
            messages: {
              required: 'Enter valid "10" digit  number'
            }
          }
        }
      ]
    },
    {
      key: 'password',
      validators: {
        fieldMatch: {
          expression: control => {
            const value = control.value;
            return (
              value.passwordConfirm === value.password ||
              // avoid displaying the message error when values are empty
              !value.passwordConfirm ||
              !value.password
            );
          },
          message: 'Password Not Matching',
          errorPath: 'passwordConfirm'
        }
      },
      fieldGroup: [
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Password',
            placeholder: 'Enter your password',
            required: true,
            minLength: 3
          },
          validators: {
            password: {
              expression: c => passwordRegex.test(c.value),
              message: (error, field: any) =>
                `Please choose a stronger password. Try a mix of upper case and lower case letters, numbers, and symbols with minimum of 8 - 15 characters
              `
            }
          },
          validation: {
            messages: {
              required: 'Use 8 characters or more for your password'
            }
          }
        },
        {
          key: 'passwordConfirm',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Confirm Password',
            placeholder: 'Re-Enter Your Password',
            required: true
          },
          validation: {
            messages: {
              required: 'Use 8 characters or more for your password'
            }
          }
        }
      ]
    }
  ];

  model: any = {};
  options: any = {};

  constructor(
    private router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
  }

  formSubmit(model): void {
    if (model !== null && model !== undefined) {
      let phonenumber = model.countrycode + model.phone;

      const user: User = {
        name: model.name,
        email: model.email,
        password: model.password.password,
        phone: phonenumber
      };

      this.loginService.register<User>(user).subscribe(
        data => {
          this.toaster.success(
            'Successfully registered, Please login with your email address and password'
          );
          this.router.navigateByUrl('/login');
        },
        error => {
          if (error === null) {
            this.toaster.error(
              'Something went wrong. Check with administrator.'
            );
          } else if (
            error !== null &&
            (error.message === null || error.message === undefined)
          ) {
            this.toaster.error(error);
          } else {
            this.toaster.error(error);
          }
        },
        () => {}
      );
    }
  }

  clearform(e): void {
    this.Fields[0].formControl.reset();
    this.Fields[1].formControl.reset();
    this.Fields[2].formControl.reset();
    this.Fields[3].formControl.reset();
    this.router.navigateByUrl('/login');
  }

  githubOnClick(): void {
  }

  facebookOnClick(): void {
  }

  linkedinOnClick(): void {
  }

  twitterOnClick(): void {
  }
}
