import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, Subscription, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { CommonEndpointService } from './common-endpoint.service';
import { ConfigurationService } from './configuration.service';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private serviceEndpoint: CommonEndpointService,
    private configurations: ConfigurationService,
    private authService: AuthService
  ) {}

  login<T>(userdetails: object | string): Observable<any> {
    const formData = userdetails || {};
    return this.http.post<User>(this.configurations.getloginUrl, formData).pipe(
      map(data => {
        localStorage.setItem('loggedInUser', JSON.stringify(data));
      
         this.authService.setLoggedInStatus(this.authService.isLoggedIn);
        this.authService.setLoggedInStatus(true);
        return data;
      })
    );
  }

  register<T>(userdetails: object | string): Observable<any> {
    const formData = userdetails || {};

    return this.http
      .post<User>(this.configurations.getRegistrationUrl, formData)
      .pipe(
        map(user => {
          return user;
        })
      );
  }

  forgotPassword(data: string | object): Observable<User> {
    return this.http
      .post<User>(this.configurations.getForgotPasswordUrl, data)
      .pipe(
        map(user => {
          return user;
        })
      );
    // return this.serviceEndpoint.getSaveEndpoint<User>(
    //   data,
    //   this.configurations.getForgotPasswordUrl
    // );
  }

  refreshToken(data: string | object): Observable<any> {
    return this.http
      .post<User>(this.configurations.getRefreshTokenUrl, data)
      .pipe(
        map((resdata: any) => {
          return resdata;
        })
      );
  }

  logout(params?: string): any {
    return this.http.post<User>(this.configurations.getLogoutUrl, {}).pipe(
      map(user => {
        this.authService.clearAllUserSessionStorages();
        this.authService.setLoggedInStatus(this.authService.isLoggedIn);
        return user;
      })
    );
  }
}
