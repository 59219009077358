import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator}from '@angular/material/paginator';
import {MatSort}from '@angular/material/sort';
import { ProgramService } from 'src/app/services/program-service';
import { ToasterService } from 'src/app/services/toaster.service';
export interface Program {
  usgProgram: string;  
  versionNo: string;
  effectiveDate: string;
  code: string | number;
  type:string|number;
  description:string;
  
}
import { Router } from '@angular/router';
const ELEMENT_DATA: Program[] = [{usgProgram: 'qqq', 
  versionNo: '1',
  effectiveDate: '1',
  code: '1',
  type:'qq',
  description:'this is description'
 }];
@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss']
})
export class ProgramDetailsComponent implements OnInit {
  displayedColumns = ['usgProgram', 'versionNo', 'effectiveDate', 'code','type','description'];
  dataSource: MatTableDataSource<Program>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private apiService: ProgramService, 
    private toaster: ToasterService, private router: Router,) {
    // Create 100 users
    // const users: UserData[] = [];
    // for (let i = 1; i <= 100; i++) { users.push(createNewUser(i)); }

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
  }
  ngOnInit(): void {
    this.getProgramList();
  }
  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  getProgramList(): void {
  //  this.apiService.getInstructorsList().subscribe(
  //    data=>{
  //      if(data.length!==0){
  //       this.dataSource = new MatTableDataSource(data);
  //      }
  //    },
  //    error=>{}
  //  );
   
  }
  goToPrograms(): void {
    this.router.navigateByUrl('/programs');
  }
}

/** Builds and returns a new User. */
// function createNewUser(id: number): UserData {
//   const name =
//       NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
//       NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

//   return {
//     id: id.toString(),
//     name: name,
//     progress: Math.round(Math.random() * 100).toString(),
//     color: COLORS[Math.round(Math.random() * (COLORS.length - 1))]
//   };
// }

/** Constants used to fill up our data base. */
// const COLORS = ['maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple',
//   'fuchsia', 'lime', 'teal', 'aqua', 'blue', 'navy', 'black', 'gray'];
// const NAMES = ['Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack',
//   'Charlotte', 'Theodore', 'Isla', 'Oliver', 'Isabella', 'Jasper',
//   'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'];

// export interface UserData {
//   id: string;
//   name: string;
//   progress: string;
//   color: string;
// }