<div class="formListingDiv">
    <div class="titleDiv">
        
        <h2>Generic Forms </h2>
    </div>
    <f1-dynamic-generic-form
    [isShowSubmitButton]="true"
    submitButtonText="Submit"
    [fields]="fields"
    [model]="formModel"
    [resetform]="resetform"
    isShowFormTitle="false"
    [isShowResetButton]="true"
    [isShowUpdateIntialValuesButton]="false"
    [isShowExtraButton]="false"
    
    isShowFormDescription="false"
    formTitle=""
    formDescription=""
    (onSubmitButtonclick)="SubmitForm($event)"
    
    >
    </f1-dynamic-generic-form>
</div>