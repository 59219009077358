<h1 mat-dialog-title>Row Action :: <strong>{{action}}</strong></h1>
<div mat-dialog-content>
  <mat-form-field *ngIf="action != 'Delete'; else elseTemplate">
    <input placeholder="{{action}} Usg Program" matInput [(ngModel)]="local_data.usgProgram">
  </mat-form-field>
  <mat-form-field *ngIf="action != 'Delete'">
    <input placeholder="{{action}} State" matInput [(ngModel)]="local_data.state">
  </mat-form-field>
  <ng-template #elseTemplate>
    Sure to delete <b>{{local_data.name}}</b>?
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>

