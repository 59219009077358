import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommonEndpointService extends EndpointFactory {
  appheaders;
  httpOptions;

  constructor(
    http: HttpClient,
    configurations: ConfigurationService,
    injector: Injector
  ) {
    super(http, configurations, injector);

    this.appheaders = this.getRequestHeaders();
    this.httpOptions = this.appheaders;
  }

  getByIdEndpoint<T>(
    id: number,
    url: string,
    params?: string | any
  ): Observable<T> {
    const endpointUrl = `${url}/?userId=${id}`;
    this.httpOptions['params'] = params;

    return this.http
      .post<T>(endpointUrl, JSON.stringify({}), this.httpOptions)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error.error, () =>
            this.getByIdEndpoint(id, url)
          );
        })
      );
  }

  getAllListEndpoint<T>(
    modelObject: any,
    url: string,
    params?: string | any
  ): Observable<T> {
    const endpointUrl = `${url}`;
    this.httpOptions['params'] = params;

    return this.http
      .post<T>(endpointUrl, JSON.stringify(modelObject), this.httpOptions)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error.error, () =>
            this.getAllListEndpoint(modelObject, url)
          );
        })
      );
  }

  listGETEndpoint<T>(url: string, params?: string | any): Observable<T> {
    const endpointUrl = `${url}`;
    this.httpOptions['params'] = params;


    return this.http.get<T>(endpointUrl, this.httpOptions).pipe<T>(
      catchError(error => {
        return this.handleError(error.error, () => this.listGETEndpoint(url));
      })
    );
  }

  getNewEndpoint<T>(
    modelObject: any,
    url: string,
    params?: string | any
  ): Observable<T> {
    this.httpOptions['params'] = params;

    return this.http
      .post<T>(url, JSON.stringify(modelObject), this.httpOptions)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error.error, () =>
            this.getNewEndpoint(modelObject, url)
          );
        })
      );
  }

  getSaveEndpoint<T>(
    modelObject: any,
    url: string,
    params?: string | any
  ): Observable<T> {
    const endpointUrl = `${url}`;
    this.httpOptions['params'] = params;

    return this.http
      .post<T>(endpointUrl, JSON.stringify(modelObject), this.httpOptions)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error.error, () =>
            this.getSaveEndpoint(modelObject, url)
          );
        })
      );
  }

  getUpdateEndpoint<T>(
    modelObject: any,
    id: string | number,
    url: string,
    params?: string | any
  ): Observable<T> {
    const endpointUrl = `${url}`;
    this.httpOptions['params'] = params;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(modelObject), this.httpOptions)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error.error, () =>
            this.getUpdateEndpoint(modelObject, id, url)
          );
        })
      );
  }

  getUpdateByIdEndpoint<T>(
    modelObject: any,
    id: string | number,
    url: string,
    params?: string | any
  ): Observable<T> {
    const endpointUrl = `${url}/${id}`;
    this.httpOptions['params'] = params;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(modelObject), this.httpOptions)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error.error, () =>
            this.getUpdateEndpoint(modelObject, id, url)
          );
        })
      );
  }

  getDeleteEndpoint<T>(
    id: Array<any> | number,
    url?: string,
    params?: string | any
  ): Observable<T> {
    const endpointUrl =
      id !== undefined && id !== null ? `${url}/${id}` : `${url}`;

    this.httpOptions['params'] = params;
    return this.http.delete<T>(endpointUrl, this.httpOptions).pipe<T>(
      catchError(error => {
        return this.handleError(error.error, () =>
          this.getDeleteEndpoint(id, url)
        );
      })
    );
  }
}
