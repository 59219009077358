<mat-sidenav #sidenav class="example-sidenav leftMenu" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
		<mat-nav-list>
			<div *ngFor="let item of navItems" [item]="item">
			<mat-list-item  class="parent" *ngIf="item.isPublic || (!item.isPublic && isAdmin)" >
				<span  class="full-width" routerLink="{{item.route}}"  >{{item.displayName}}</span>
				<mat-icon mat-list-icon >{{item.iconName}}</mat-icon>
				<mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
			</mat-list-item>
		</div>
			<mat-list-item  class="parent">
				<span  class="full-width" (click)="logoutUser($event)" >Logout</span>
				
			</mat-list-item>
			
    </mat-nav-list>
    <mat-nav-list>
</mat-nav-list>
	</mat-sidenav>

