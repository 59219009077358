import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login-service';
import { User } from 'src/app/models/user.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { AuthService } from 'src/app/services/auth.service';

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotpasswordComponent implements OnInit {
  title = 'Forgot Password';
  informationDetails = 'You can reset your password here.';
  Fields: any = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email',
        placeholder: 'Enter your email address',
        required: true
      },
      validators: {
        email: {
          expression: c => emailRegex.test(c.value),
          message: (error, field: any) =>
            'Enter a valid registered email address'
        }
      },
      validation: {
        messages: {
          required: 'Enter a valid registered email address'
        }
      }
    }
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
  }

  formSubmit(model): void {
    this.loginService.forgotPassword(model).subscribe(
      data => {
        this.toaster.success(
          'Your password has been sent to your email address.'
        );
        this.router.navigateByUrl('/login');
      },
      error => {

        if (error === null) {
          this.toaster.error('Something went wrong. Check with administrator.');
        } else if (
          error !== null &&
          (error.message === null || error.message === undefined)
        ) {
          this.toaster.error(error);
        } else {
          this.toaster.error(error.message);
        }
      },
      () => {}
    );
  }

  clearform(e): void {
    this.Fields[0].formControl.reset();
    this.router.navigateByUrl('/login');
  }
}
