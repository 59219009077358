<div class="imgDiv">
  <f1-carousel
  [sliderArray]="items"
  intervals="5000"
  slideDirection="right"
  [showArrows]="true"
  [showIndicators]="false"
  [showCaptions]="true"
  width="55%"
  height="700"
>
</f1-carousel>
</div>
<div class="loginPage">
  <div class="newUserDiv">New User? <a [routerLink]="['/register']">Register here!</a></div>
  <div class="innerForm">
  <f1-signin
    [model]="model"
    [fields]="Fields"
    title="Welcome to Fulcrum Digital University!!"
    submitButtonLable="LOG IN"
    [displayForgotPass]="false"
    [displayRegister]="false"
    RegisterHereText="Don't have an account? Register here"
    (formSubmit)="formSubmit($event)"
    (register)="register()"
    forgetPasswordText="Forgot password"
    (forgotPassword)="forgotPassword()"
  ></f1-signin>

  <div class="row extraRow">
    <div class="col-sm-6 col-xs-6">
      <input type="checkbox" [(ngModel)]="rememberMe" ID="remberMe" />
      <label for="remberMe">Remember Credentials</label>
    </div>
    <div class="col-sm-6 col-xs-6">
      <a (click)="forgotPassword()" class="forLink">Recover Password ?</a>
    </div>
  </div>
  <div class="linkSet">
    <button class="dropdown-item" [routerLink]="['/register']">
      <p>Don't have an account? Register here</p>
    </button>
  </div>
</div>
</div>
