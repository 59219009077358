import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import {MatPaginator}from '@angular/material/paginator';
import {MatSort}from '@angular/material/sort';
import { ɵNgNoValidate } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from './../dialog-box/dialog-box.component';
@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss']
})
export class RatingsComponent implements OnInit {
  displayedColumns = ['actions','usgProgram','state', 'rate1', 'rate2', 'rate3', 'rate4'];
  dataSource = new MatTableDataSource<Element>();
  @ViewChild(MatTable,{static:true}) table: MatTable<any>;

  filterValues = {};
  filterSelectObj = [];
  nameFilter = new FormControl();
isAdmin:boolean;
  constructor(private toaster: ToasterService,private router: Router,public dialog: MatDialog){
    // Object to create Filter for
    this.filterSelectObj = [
      {
        name: 'Usg Program',
        columnProp: 'usgProgram',
        options: []
      }, {
        name: 'State',
        columnProp: 'state',
        options: []
      }
    ]
  }
ngOnInit(): void {
  const userdata = JSON.parse(localStorage.getItem('loggedInUser'));
    if (userdata.roleID === '2') {
      //isAdmin
      localStorage.setItem('isAdmin', "true");
      localStorage.setItem('isCarrier', "false");
     this.isAdmin = true;
    } else {
      localStorage.setItem('isCarrier', "true");
      localStorage.setItem('isAdmin', "false");
      this.isAdmin = false;
     
    }
    
  this.getRemoteData();
  // Overrride default filter behaviour of Material Datatable
  this.dataSource.filterPredicate = this.createFilter();
}
// Get Uniqu values from columns to build filter
getFilterObject(fullObj, key) {
  const uniqChk = [];
  fullObj.filter((obj) => {
    if (!uniqChk.includes(obj[key])) {
      uniqChk.push(obj[key]);
    }
    return obj;
  });
  return uniqChk;
}
createFilter() {
  let filterFunction = function (data: any, filter: string): boolean {
    let searchTerms = JSON.parse(filter);
    let isFilterSet = false;
    for (const col in searchTerms) {
      if (searchTerms[col].toString() !== '') {
        isFilterSet = true;
      } else {
        delete searchTerms[col];
      }
    }


    let nameSearch = () => {
      let found = false;
      if (isFilterSet) {
        for (const col in searchTerms) {
          searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
            if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
              found = true
            }
          });
        }
        return found
      } else {
        return true;
      }
    }
    return nameSearch()
  }
  return filterFunction
}

applyFilter(filterValue: string) {
  filterValue = filterValue.trim(); // Remove whitespace
  filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  this.dataSource.filter = filterValue;
}
  // Reset table filters
 resetFilters() {
  this.filterValues = {}
  this.filterSelectObj.forEach((value, key) => {
    value.modelValue = undefined;
  })
  this.dataSource.filter = "";
}
 // Get remote serve data using HTTP call
 getRemoteData() {

 
  this.dataSource.data = ELEMENT_DATA;

  this.filterSelectObj.filter((o) => {
    o.options = this.getFilterObject(this.dataSource.data, o.columnProp);
  });
}
 // Called on Filter change
 filterChange(filter, event) {
  //let filterValues = {}
  this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
  this.dataSource.filter = JSON.stringify(this.filterValues)
}
 // Creates new user.
//  createNewRow(id: number): Element {
  

//   //return {id:"", usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory 1',carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" };
// }
 // Adds new user.
 addRow() {
  //this.dataSource.data.push(this.createNewRow(this.dataSource.data.length + 1));
  this.dataSource.filter = "";
}
// --------------- DELETE ------------------

public deleteRecord(recordId) {
  

  // For delete confirm dialog in deleteItem to match the db column name to fetch.
  this.deleteRowDataTable (recordId);
     
}

// Remove the deleted row from the data table. Need to remove from the downloaded data first.
private deleteRowDataTable (recordId) {
  
  const itemIndex = this.dataSource.data.findIndex(obj => obj["id"] === recordId);
  this.dataSource.data.splice(itemIndex, 1);
  console.log(this.dataSource.data);
  this.dataSource.filter = "";
  
}
copyToAllRowsForRate1(e,i){


if(confirm("Are you sure you want to copy the content to all rows of column? ")) {
  const arr = this.dataSource.data;

  arr.filter((obj) => {
   
   
    obj.rate1 = e;
   
    //return obj;
  });
}

}
copyToAllRowsForRate2(e,i){


  if(confirm("Are you sure you want to copy the content to all rows of column? ")) {
    const arr = this.dataSource.data;
  
    arr.filter((obj) => {
     
     
      obj.rate2 = e;
     
      //return obj;
    });
  }
  
  }
  copyToAllRowsForRate3(e,i){


    if(confirm("Are you sure you want to copy the content to all rows of column? ")) {
      const arr = this.dataSource.data;
    
      arr.filter((obj) => {
       
       
        obj.rate3 = e;
       
        //return obj;
      });
    }
    
    }
    copyToAllRowsForRate4(e,i){


      if(confirm("Are you sure you want to copy the content to all rows of column? ")) {
        const arr = this.dataSource.data;
      
        arr.filter((obj) => {
         
         
          obj.rate4 = e;
         
          //return obj;
        });
      }
      
      }
      saveRates(){this.toaster.success("Data successfully saved");}
      cancelSave(){this.toaster.error("Functionality is not implemented.");}
      openDialog(action,obj) {
        obj.action = action;
        const dialogRef = this.dialog.open(DialogBoxComponent, {
          width: '250px',
          data:obj
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result.event == 'Add'){
            this.addRowData(result.data);
          }else if(result.event == 'Delete'){
            this.deleteRowData(result.data);
          }
        });

}
addRowData(row_obj){
  var d = new Date();
  this.dataSource.data.push({
    id:d.getTime(),
    usgProgram:row_obj.usgProgram,
    state: row_obj.state,
  
    rate1:"",rate2:"",rate3:"",rate4:"" 
  });
  this.table.renderRows();
  
}

deleteRowData(row_obj){
  this.dataSource.data = this.dataSource.data.filter((value,key)=>{
    return value.id != row_obj.id;
  });
}
}
 

export interface Element {
  id:number;
    state: string;
  usgProgram: string;
  
  "rate1":string;
   "rate2":string;
    "rate3":string;
     "rate4":string;
}
const ELEMENT_DATA: Element[] = [
    { id:1,usgProgram: "91127-Alarm and Alarm Installers", state: 'CA', rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:2, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA', rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:3, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA', rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:4, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA',rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:5, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA',rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:6, usgProgram: "81127-Adult Day Care", state: 'FL',rate1:"",rate2:"",rate3:"",rate4:""},
    {id:7, usgProgram: "81127-Adult Day Care", state: 'FL',rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:8, usgProgram: "81127-Adult Day Care", state: 'FL', rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:9, usgProgram: "81127-Adult Day Care", state: 'FL',rate1:"",rate2:"",rate3:"",rate4:"" },
    {id:10, usgProgram: "81127-Adult Day Care", state: 'FL', rate1:"",rate2:"",rate3:"",rate4:"" },
  
  ];
  


// const ELEMENT_DATA: Element[] = [
//   { id:1,usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory1',carrierProgram:'Century Surety Company',rateGroup:'General Liability', rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:2, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory2', carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:3, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory3', carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:4, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory4',carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:5, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory5',carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:6, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory6',carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:""},
//   {id:7, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory7',carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:8, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory8', carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:9, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory9',carrierProgram:'Century Surety Company', rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },
//   {id:10, usgProgram: "91127-Alarm and Alarm Installers", state: 'CA-Teritory10', carrierProgram:'Century Surety Company',rateGroup:'General Liability',rate1:"",rate2:"",rate3:"",rate4:"" },

// ];

