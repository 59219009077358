import { Component, OnInit, OnDestroy,ViewEncapsulation } from '@angular/core';

import { of } from 'rxjs'
import { map, reduce } from 'rxjs/operators'
import { Subject,Subscription } from 'rxjs';
import { takeUntil, startWith, tap } from 'rxjs/operators';
import {ApplicationService} from './../app/services/applicationService';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { ConfirmSessionDialogService } from './services/dialog-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'fulcrumDigitalUniversity';

  
  subscription: Subscription;
  isUserLoggedIn: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  sessioncount: any = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private popupsessionservice: ConfirmSessionDialogService,
    private applicationService: ApplicationService,
  ) {
    this.subscription = this.authService.getLoggedInStatus().subscribe(flag => {
      this.isUserLoggedIn = flag;
    });
   
    
   
  }
  ngOnInit(): void {
    console.log('this.router.url', this.router.url);
    const ret = this.authService.isSesionExpired('loggedInUser');
    
    this.authService.setLoggedInStatus(this.authService.isLoggedIn);
   
    this.subscription = this.authService.currentsessionAboutToExpireObj.subscribe(
      message => {
        if (message && message.flag === 'session_about_to_expire') {
          this.sessioncount = this.sessioncount + 1;
          if (this.sessioncount === 1) {
            this.popupsessionservice.open(null);
          }
          this.popupsessionservice.confirmed().subscribe(confirmed => {
            if (confirmed) {
              this.sessioncount = 0;
            } else {
              this.sessioncount = 0;
            }
          });
        }
      }
    );
    console.log("====>>>"+this.isUserLoggedIn);
  }

 
  //title = 'f1-dynamic-form';
  submitButtonText = "Submit Here";

 
 

  
    isShowFooterHeader = true;
  isShowCopryRightContainer = true;
  isShowBodyFooter = true;
  backGroundHeaderColor = "#ccc";
  backGroundFooterColor = "#ccc";
  sampleText = "This is sample text";
  sampleTitle = "Sample Title";
  sampleHeader = " Sample Header";
  footerHeaderContent = `<div class="row">
  <div class="col-md-6">
    <span>footer header content</span>
  </div>

  <div class="col-md-6">
    <div class="footer-header-menu">
      <ul>
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#">Terms</a>
        </li>
        <li>
          <a href="#">Privacy Policy</a>
        </li>
        <li>
          <a href="#">Contact</a>
        </li>
      </ul>
    </div>
  </div>

</div>`;
  footerColumnWiseContent = [
    `<h3>Site Map</h3><ul class="list-unstyled three-column"><li>Home</li><li>Services</li><li>About</li><li>Code</li><li>Design</li><li>Host</li><li>Contact</li><li>Company</li></ul><ul class="list-unstyled socila-list"><li><img src="http://placehold.it/48x48" alt="" /></li><li><img src="http://placehold.it/48x48" alt="" /></li><li><img src="http://placehold.it/48x48" alt="" /></li><li><img src="http://placehold.it/48x48" alt="" /></li><li><img src="http://placehold.it/48x48" alt="" /></li><li><img src="http://placehold.it/48x48" alt="" /></li></ul> `,
    `<h3>latest Articles</h3>
  <div class="media">
 <a href="#" class="pull-left">
   <img src="http://placehold.it/64x64" alt="" class="media-object" />
 </a>
 <div class="media-body">
   <h4 class="media-heading">Programming</h4>
   <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
 </div>
</div>

<div class="media">
 <a href="#" class="pull-left">
   <img src="http://placehold.it/64x64" alt="" class="media-object" />
 </a>
 <div class="media-body">
   <h4 class="media-heading">Coding</h4>
   <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
 </div>
</div>

<div class="media">
 <a href="#" class="pull-left">
   <img src="http://placehold.it/64x64" alt="" class="media-object" />
 </a>
 <div class="media-body">
   <h4 class="media-heading">Web Sesign</h4>
   <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
 </div>
</div> `,
    `  <h3>Our Work</h3>
  <img class="img-thumbnail" src="http://placehold.it/150x100" alt="" />
 <img class="img-thumbnail" src="http://placehold.it/150x100" alt="" />
 <img class="img-thumbnail" src="http://placehold.it/150x100" alt="" />
 <img class="img-thumbnail" src="http://placehold.it/150x100" alt="" /> `,
  ];

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.subscription.unsubscribe();
  }
 
 
}

