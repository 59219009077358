import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';
import { LoaderService } from '../custom-loader/loader.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  
  subscription: Subscription;
  isUserLoggedIn: any;
  timeData = '0';
  showhidetimer = null;
  config = { leftTime: this.timeData, demand: true, notify: [1] };

  timerevent: any = { action: '' };

  constructor(
    private router: Router,
    private authService: AuthService,
    private toaster: ToasterService,
   
    private _loader: LoaderService
  ) {
    this.subscription = this.authService
      .getLoggedInUser()
      .subscribe(isLoggedIn => {
        this.isUserLoggedIn = isLoggedIn;
      });
     
  }
  imageUrl = "../../assets/images/0.jpg";
  imageHeight = "30";
  imageWidth = "30";
  headerModules = [
    { id: 1, url: "/", name: "Home", isEnabled: true, isDropdown: false },
  
    
    ];
  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
 
  }
  logoutUser(e): void {
    this.authService.clearAllUserSessionStorages();
  }

  

 
  redirectToDashboard():void{
   let roleId= JSON.parse(
      localStorage.getItem('loggedInUser')
    ).roleID;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  functionalityToBeImplemented(){
    this.toaster.error("Page is yet to be implemented");
  }
  goToHome(){
    this.router.navigateByUrl('/dashboard');
  }

}
