import { Component, OnInit } from '@angular/core';

import {of} from 'rxjs'
import {map, reduce} from 'rxjs/operators'
import { Subject } from 'rxjs';
import { takeUntil, startWith, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import {ApplicationService} from 'src/app/services/applicationService';
import { Program } from 'src/app/models/program.model';
import { ProgramService } from 'src/app/services/program-service';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  constructor(
    private AuthService:AuthService, 
    private applicationService: ApplicationService,
  
    private toaster: ToasterService,
    private router: Router,) { }

  ngOnInit(): void {
  }
 
 

  onDestroy$ = new Subject<void>();
  title = 'f1-dynamic-form';
  submitButtonText = "Submit Here";
  
  fields = [
    {
      label: 'Personal',
      backButtonText: 'back',
      nextButtonText: 'next',
      fields: [
        {
          key: 'title',
          type: 'input',
          templateOptions: {
            label: 'Title',
            required: false
          },

          validation: {
            messages: {

            }
          }
        },
        {
          key: 'name',
          type: 'input',
          templateOptions: {
            label: 'Name',
            required: false
          },

          validation: {
            messages: {

            }
          }
        },
        {
          key: 'birthdate',
          type: 'datepicker',
          templateOptions: {
            label: 'Birthdate',
            required: false
          },

          validation: {
            messages: {

            }
          }
        },
        {
          key: 'gender',
          type: 'input',
          templateOptions: {
            label: 'Gender',
            required: false
          },

          validation: {
            messages: {

            }
          }
        },
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            label: 'Email',
            required: false
          },

          validation: {
            messages: {

            }
          }
        },

        {
          key: 'phone',
          type: 'input',
          templateOptions: {
            label: 'Phone',
            required: false
          },

          validation: {
            messages: {

            }
          }
        },
        {
          key: 'address',
          type: 'input',
          templateOptions: {
            label: 'Address',
            required: false
          },

          validation: {
            messages: {

            }
          }
        },
      ]
    },
    {
      label: 'Course',
      backButtonText: 'back',
      nextButtonText: 'next',
      fields: [
        {
          key: 'course_code',
          type: 'input',
          templateOptions: {
            label: 'Course Code'
          },

        },
        {
          key: 'institution_code',
          type: 'input',
          templateOptions: {
            label: 'Institution Code'
          },
          expressionProperties: {


          }
        },
        {
          key: 'course_startdate',
          type: 'datepicker',
          templateOptions: {
            label: 'Course StartDate'
          },
          expressionProperties: {


          }
        },

      ]
    },
    {
      label: 'Education',
      backButtonText: 'back',
      nextButtonText: 'next',
      fields: [
        {
          key: 'prev_college_name',
          type: 'input',
          templateOptions: {
            label: 'Previous College Name',

          }
        },
        {
          key: 'prev_course_end_date',
          type: 'datepicker',
          templateOptions: {
            label: 'Previous Course End Date',

          }
        },
        {
          key: 'prev_percentage',
          type: 'input',
          templateOptions: {
            label: 'Previous Scored Percentage',
            type: 'number',

          }
        },
        {
          key: 'prev_course_attendance',
          type: 'input',
          templateOptions: {
            label: 'Previous Course Attendance',

          }
        },
      ]
    },
    {
      label: 'Grant Options',
      backButtonText: 'back',
      nextButtonText: 'next',
      fields: [
        {
          key: 'scholarship',
          type: 'input',
          templateOptions: {
            label: 'Scholarship'
          },

        },
        {
          key: 'applicant origin',
          type: 'input',
          templateOptions: {
            label: 'Applicant Origin',

          }
        },
        {
          key: 'isServedArmy',
          type: 'input',
          templateOptions: {
            label: 'Has Any of the parent served in Army?',

          }
        },
      ]
    },
    {
      label: 'Declaration',
      backButtonText: 'back',
      nextButtonText: 'next',
      fields: [
        {
          key: 'is Term Accepted',
          type: 'checkbox',
          templateOptions: {
            label: 'I agree to the Fulcrumdigital university Terms and Conditions.',
            appearance: 'none',
            required: true,
            options: [
              { value: 1, label: 'Pay Now' }
              // { value: 2, label: 'Pay Later' },
              // { value: 3, label: 'Pay Partial' }
            ]
          }
        },


      ]
    }

  ];
  ngOnDestroy(): void {
  this.onDestroy$.next();
      this.onDestroy$.complete();
  }

  SubmitForm(e): void {
    console.log(e.model);
    const program: Program = {
      email:e.model.email,
      birthdate:e.model.birthdate,
      name:e.model.name,
      clientid:'F1',
      score:e.model.prev_percentage
     
    };

    this.applicationService.saveApplication<Program>(program).subscribe(
      data => {
        
        console.log("data>>",data);
        this.toaster.success(data.Responsemessage);
        //this.getProgramList();
      },
      error =>{
        this.toaster.error("Something Went wrong");
      },
      () => {}
      );

  }
  prevclicked(e) {
   // alert("prevclickedhere");
  }
  nextclicked(e) {
   // alert("herenextclicked");
  }
}


