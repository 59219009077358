import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  pure: false
})
export class SearchSortPipe implements PipeTransform {
  public transform(value, keys: string, term: string): void {
    if (!term) {
      return value;
    }

    return (value || []).filter(item =>
      keys.split(',').some(key => {
        return (
          item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])
        );
      })
    );
  }
}
