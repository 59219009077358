import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-form-generic',
  templateUrl: './form-generic.component.html',
  styleUrls: ['./form-generic.component.scss']
})
export class FormGenericComponent implements OnInit {
  formObj = new FormGroup({});
  formModel = {};
  resetform: boolean = false;
  fields = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-3',
          key: 'Program',
          type: 'select',
          templateOptions: {
            label: 'Program',
            placeholder: 'Placeholder',
           
            required: true,
            options: [
              { value: "Program1", label: 'Program 1' },
              { value: "Program2", label: 'Program 2'  }
             
              
            ],
          },
        },
        {
          className: 'col-3',
          type: 'input',
          key: 'versionNo',
          templateOptions: {
            label: 'Version No',
          },
          
        },
        {
          className: 'col-3',
          type: 'input',
          key: 'code',
          templateOptions: {
            label: 'Code',
          },
          
        },
        {
          className: 'col-3',
          key: 'effectiveDate',
      type: 'datepicker',
      templateOptions: {
        label: 'Effective Date',
        placeholder: 'Placeholder',
        startAt:'',
          minDate:new Date("06/14/2021"),
          maxDate:'',
       
        required: true,
      },
          
        },
      ],
    },
    
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
            className: 'col-3',
            key: 'type',
            type: 'select',
            templateOptions: {
              label: 'Type',
              placeholder: 'Placeholder',
             
              required: true,
              options: [
                { value: 1, label: 'General Liability' },
                { value: 2, label: 'Property'  },
                
              ],
            },
          },
        {
          className: 'col-9',
          type: 'input',
          key: 'description',
          templateOptions: {
            label: 'Description',
          },
        },
        
      ],
    },
    
      ];
  constructor() { }

  ngOnInit(): void {
  }
  SubmitForm(e): void {
    // const program: Program = {
    //   usgProgram: e.model.usgprogram,
    //   clientid:'AT',
    //   code:e.model.code,
    //   versionNo:e.model.versionNo,
    //   type:e.model.type,
    //   effectiveDate:e.model.effectiveDate,
    //   description:e.model.description,
     
    // };
    // this.programService.saveProgram<>(program).subscribe(
    //   data => {
    //     console.log(e.model);
    //     console.log("data>>",data);
    //     this.toaster.success(data.Responsemessage);
    //     this.getProgramList();
    //   },
    //   error =>{
    //     this.toaster.error("Something Went wrong");
    //   },
    //   () => {}
    //   );

  }

}
