<div class="header">
  <h1 mat-dialog-title>Session About To Expire</h1>
</div>
<div mat-dialog-content>
  <p class="dialog-message">
    Your session will expire after 10 minutes. For continue using application,
    please click continue button.
  </p>
</div>
<div mat-dialog-actions>
  <button class="btn btn-cancel" style="margin-right:10px;" (click)="logout()">
    logout
  </button>
  <button class="btn" (click)="continue()">Continue</button>
</div>
