import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import {ProgramsComponent} from './components/programs/programs.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { CanDeactivateGuard } from './services/deactivate-guard';
import { ProgramDetailsComponent} from './components/program-details/program-details.component';
import {PageUnderConstructionComponent} from './components/page-under-construction/page-under-construction.component';
import {RatingsComponent} from './components/ratings/ratings.component';
import {FormGenericComponent} from './components/form-generic/form-generic.component';
import {UserLibraryComponent} from './components/user-library/user-library.component';
import {FormsComponent} from './components/forms/forms.component';
import { HelpComponent} from './components/help/help.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { module: 'Dashboard' }
  },
  {
    path: 'forgotpassword',
    pathMatch: 'full',
    component: ForgotpasswordComponent
  },
  
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full',
    data: { title: 'Register' }
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    data: { title: 'Login' }
  },
  {
    path: 'programs',
    component: ProgramsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { title: 'Program' }
  },
  {
    path: 'forms',
    component: FormsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { title: 'Forms' }
  },
  {
    path: 'program-listing',
    component: ProgramDetailsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { title: 'Program-Listing' }
  },
  {
    path: 'form-generic',
    component: FormGenericComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { title: 'Form-Generic' }
  },
  
  
  
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        useHash: true
       
      }
     
    )
  ],
  exports: [RouterModule],
  providers: [AuthGuard, AuthService]
})
export class AppRoutingModule {}
