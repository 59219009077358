import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ChartDataSets,ChartType, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isShow: boolean = false;
  username;
  userRole;
  roleId;
  userdata;
 
  constructor(
    private router: Router,
    public dialog: MatDialog,
   
    private authService: AuthService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem('loggedInUser'));
   // console.log(this.userdata.roleID);
    this.username = "";//this.userdata.email;
    
   // if(this.userdata.roleID == "1"){
      this.userRole = "Carrier";
      
    // }else{
    //   this.userRole = "Admin";
    // }
    
    }
    isUserLoggedIn = true;
    columns = [
      {
        name: "name",
        width: 300,
        frozenLeft: true,
        frozenRight: false,
        inlineOption: "input",
      },
      {
        name: "gender",
        width: 100,
        frozenLeft: false,
        frozenRight: false,
        inlineOption: "select",
      },
      { name: "Age", width: 100, frozenLeft: false, frozenRight: false },
      { name: "City", width: 150, frozenLeft: false, frozenRight: false },
      { name: "State", width: 300, frozenLeft: false, frozenRight: true },
    ];
    allColumns = [
      { name: "name", width: 100 },
      { name: "Gender", width: 200 },
      { name: "Age", sortable: false, width: 300 },
      { name: "City", width: 200 },
      { name: "State", width: 200 },
    ];
    //classForTable = "material selection-cell";
    styleWidthProp = "90";
    datajsonUrl = "../assets/data/company.json"; 

 
    
}
