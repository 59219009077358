import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { scheduled, Subscription } from 'rxjs';
import { delay, startWith, tap } from 'rxjs/operators';
import { LoaderService, LoaderState } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewInit, OnDestroy {
  show = false;
  private subscription: Subscription;

  constructor(
    private loaderService: LoaderService,
    private cdref: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
    this.subscription = this.loaderService.loaderState
      .pipe(
        delay(0),
        tap((state: LoaderState) => (this.show = state.show))
      )
      .subscribe();
    /* .subscribe(
      (state: LoaderState) => {
        this.show = state.show;
      }
    ); */
  }

  ngOnInit(): void {
    /* this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.show = state.show;
      }
    ); */
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
