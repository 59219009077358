<div class="formsDiv">
    <div class="titleDiv">
    
         <h2>Application Form For Post Graduation  <mat-icon>info</mat-icon>
        </h2> 
    </div> 
    <div style="padding: 10px;">
        <f1-dynamic-json-form
        [isShowSubmitButton]="false"
        isShowStepsText = "true "
        [submitButtonText]="submitButtonText"
        [fields]="fields"
        textForSteps="Application form step"
        classForShowText=""
        textorsymbolforseperator="/"
        (onButtonclick)="SubmitForm($event)"
        (onPrevStep)="prevclicked($event)"
        (onNextStep)="nextclicked($event)"
        >
        </f1-dynamic-json-form>
    </div>
</div>