/* Configure common routes */

import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigurationService {
  public get getloginUrl(): string {
    return this.baseUrl + 'generateToken';
  }
  public get getSaveProgramDetailsUrl(): string {
    return 'https://f1-dynamicforms-crudoperation.fulcrumone.net/api/addnewdynamicdata';
  }
  public get getSaveProgramDetailsListingUrl(): string {
    return 'https://f1-dynamicforms-crudoperation.fulcrumone.net/api/getdynamicdatabyclientid/F1';
  }
  public get getRegistrationUrl(): string {
    return this.baseUrl + 'Createnewuser';
  }

  public get getForgotPasswordUrl(): string {
    return this.baseUrl + 'ForgotPassword';
  }

  public get getRefreshTokenUrl(): string {
    return this.baseUrl1 + 'token/refresh';
  }
public get getLogoutUrl(): string {
    return this.baseUrl + 'logout';
  }

 public static readonly appVersion: string = '1.0.0';
  public seperator = 'api/';
  public baseUrl: string = environment.API_URL + this.seperator;
  public baseUrl1: string = environment.API_URL1 + this.seperator;

  public loginUrl = '/login';
  public get saveApplicationUrl(): string {
    return 'https://fulcrumoneworkflowengine.fulcrumone.net/path1';
  }
  public get approveOrRejectApplicationUrl(): string {
    return 'https://fulcrumoneworkflowengine.fulcrumone.net/approveOrRejectGrant';
  }

  // ***End of defaults***
  constructor() {}
}
