<div class="imgDiv"></div>
<div class="regiPage">
  <f1-signup
    [model]="model"
    [fields]="Fields"
    [options]="options"
    [title]="title"
    submitButtonLable="Register"
    isFacebookLable="Facebook"
    isLinkedinLable="Linkedin"
    isTwitterLable="Twitter"
    [isFacebook]="false"
    [isLinkedin]="false"
    [isTwitter]="false"
    (formSubmit)="formSubmit($event)"
    (githubOnClick)="githubOnClick()"
    (facebookOnClick)="facebookOnClick()"
    (linkedinOnClick)="linkedinOnClick()"
    (twitterOnClick)="twitterOnClick()"
  ></f1-signup>

  <button
    type="button"
    class="btn btn-default btnReset"
    (click)="clearform($event)"
  >
   X
  </button>
</div>
