<div class="dashboardPage wrapper programListDiv">
    <div class="row">
        <div class="col-md-1">test</div>
        <div class="col-md-2 programDiv">
            <div class="programInner">
                <h5>Program</h5>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
                <!-- <a mat-flat-button href="/programs" ><mat-icon>plus</mat-icon> Program</a> -->
            </div>
            <div class="example-button-row listLink">
                <a mat-flat-button [routerLink]="['/program-listing']" color="primary"><mat-icon>group</mat-icon> Program Dashboard</a>
                <a mat-flat-button  [routerLink]="['/programs']" (click)="goToPrograms()" ><mat-icon>group</mat-icon> Program Details</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Rate Groups</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Classification</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Limits</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> State/ Territory</a>
                <a mat-flat-button  [routerLink]="['/ratings']" ><mat-icon>group</mat-icon> Rate</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Limits</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Minimum Premiums</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Territories</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Categories</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Deductibles</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Surcharges/Credits</a>
                <a mat-flat-button  [routerLink]="['/program-listing']"><mat-icon>group</mat-icon> Application</a>
              </div>
        </div>
        <div class="col-md-9">
            <div class="titleDiv">
              <div class="profileList"><span class="num">+4</span><mat-icon>account_circle</mat-icon> <mat-icon>account_circle</mat-icon> <mat-icon>account_circle</mat-icon><div class="clearfix"></div></div>
                <h2>Program Listing</h2>
            </div>
            <div class="example-container mat-elevation-z8">

                <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="usgProgram">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Usg Program</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.usgProgram}} </mat-cell>
                  </ng-container>
                
              
                <ng-container matColumnDef="versionNo">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Version No </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.versionNo}} </mat-cell>
                </ng-container>
              
                <ng-container matColumnDef="code">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Code </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.code}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
                  </ng-container>
                <ng-container matColumnDef="effectiveDate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Effective Date </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.effectiveDate}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                  </ng-container>
                  <!-- <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="action-link"> 
                        <a (click)="openDialog('Update',element)">Edit</a>
                       
                    </mat-cell>
                  </ng-container> -->
              
                <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
              </mat-table>
            
              <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    
        </div>
    </div>
    
    
    
    </div>