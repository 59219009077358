import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ConfigurationService } from './configuration.service';
// import ticksToDate from 'ticks-to-date';
// import totalMinutes from 'ticks-to-date';
import { environment } from 'src/environments/environment';
import { ConfirmSessionDialogService } from './dialog-session.service';

@Injectable()
export class AuthService {
  public loginRedirectUrl: string;
  private localLoginStatus = new Subject<boolean>();
  private getUserDetails: BehaviorSubject<any>;
  private loggedInStatus = new Subject<any>();
  public subject = new Subject<any>();
  public isUserLoggedIn;
  private timer = new BehaviorSubject(null);
  currentTimer = this.timer.asObservable();

  private sessionabouttoexpire = new BehaviorSubject(null);
  currentsessionAboutToExpireObj = this.sessionabouttoexpire.asObservable();
  public isUserLoggedIn123: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // sessioncount = 0;

  constructor(
    private router: Router,
    private configurations: ConfigurationService
  ) {
    this.getUserDetails = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('loggedInUser'))
    );
    
  }

  changeTimer(data: string = ''): void {
    this.timer.next(data);
  }

  updateSessionAboutExpireValue(data: any | string = ''): void {
    this.sessionabouttoexpire.next(data);
  }

  isSesionExpired(key?: string): any {
    const itemStr = localStorage.getItem('loggedInUser');
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    // const date =
    //   item.tokenGeneration !== null
    //     ? ticksToDate(item.tokenGeneration.expiration)
    //     : null;

    //const expirationDate: any = new Date(date);
   // const currentDate: any = new Date();

   // let seconds: any;
    //let diff: any = expirationDate.getTime() - currentDate.getTime();

    // seconds = Math.floor(diff / 1000);
    // let hour = Math.floor((seconds / 3600) % 24);
    // let minute = Math.floor((seconds / 60) % 60);
    // let second = seconds % 60;

    // if (currentDate.getTime() > expirationDate.getTime()) {
    //   // this.sessioncount = 0;
    //   // stopTimer
    //   this.clearAllUserSessionStorages();
    //   return true;
    // } else {
      // if (hour < 1 && minute <= environment.APPLICATION_TIMEOUT) {
      //   // set observable and call popup code from app.component
      //   this.updateSessionAboutExpireValue({ flag: 'session_about_to_expire' });
      // } else {
      //   this.updateSessionAboutExpireValue({ flag: '' });
      //   // for testing purpose
      //   // this.updateSessionAboutExpireValue({ flag: 'session_about_to_expire' });
      // }
      // startTimer
     // return false;
   // }
  }

  clearAllUserSessionStorages(): void {
    localStorage.removeItem('loggedInUser');
    this.isUserLoggedIn = false;
    sessionStorage.clear();
    sessionStorage.clear();
    localStorage.clear();
    this.setLoggedInStatus(this.isLoggedIn);
    this.router.navigate(['/login']);
  }

  getLoggedInUser(): any {
    return this.getUserDetails.asObservable();
  }

  getCurrentUser(): any {
    return this.getUserDetails.value;
  }

  getCurrentUserId(): any {
    return this.getUserDetails.value.userId;
  }

  getCurrentUserEmail(): any {
    return this.getUserDetails.value.email;
  }

  getCurrentUserPhone(): any {
    return this.getUserDetails.value.phoneno;
  }

  public getCurrentUserRefreshToken(): any {
    return this.getUserDetails.value.tokenGeneration.refreshToken;
  }

  getLoggedInStatus(): Observable<any> {
    return this.loggedInStatus.asObservable();
  }

 

  public setLoggedInStatus(name: any): void {
    this.getUserDetails.next(JSON.parse(localStorage.getItem('loggedInUser')));
    this.loggedInStatus.next(name);
  }

  public setIsAdminLoggedInStatus(name: any): void {
    this.getUserDetails.next(JSON.parse(localStorage.getItem('loggedInUser')));
    this.loggedInStatus.next(name);
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this.localLoginStatus.asObservable();
  }

  public setStatusLogin(name: any): void {
    this.localLoginStatus.next(name);
  }

  get isLoggedIn(): boolean {
    if (localStorage.getItem('loggedInUser') !== null) {
      const ifUserLoggedIN = JSON.parse(localStorage.getItem('loggedInUser'))
        .tokenGeneration.accessToken;
      return ifUserLoggedIN &&
        ifUserLoggedIN !== null &&
        ifUserLoggedIN !== undefined
        ? true
        : false;
    } else {
      return false;
    }
  }
  get isLoggedInUserAdmin(): boolean {
    if (localStorage.getItem('loggedInUser') !== null) {
      // const ifUserLoggedIN = JSON.parse(localStorage.getItem('loggedInUser'))
        // .tokenGeneration.accessToken;
        const userdata = JSON.parse(localStorage.getItem('loggedInUser'));
        console.log(userdata);
        let isAdmin = Number(userdata.roleID);
      return isAdmin == 2
        ? true
        : false;
    } else {
      return false;
    }
  }
}
