<div class="dashboardPage wrapper">
  <div class="titleDiv">
      
      <h2>Welcome to Fulcrumdigital University portal</h2>
  </div>
  <div class="container">
    <div class="card-deck">
      <div class="card shadow-lg p-3 mb-5 bg-white rounded">
        <img class="card-img-top" src="../../../assets/img/1.jpg" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
          <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
      </div>
      <div class="card shadow-lg p-3 mb-5 bg-white rounded">
        <img class="card-img-top" src="../../../assets/img/4.jpg" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
          <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
      </div>
      <div class="card shadow-lg p-3 mb-5 bg-white rounded">
        <img class="card-img-top" src="../../../assets/img/3.jpg" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
          <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
        </div>
      </div>
    </div>
     
    
<!-- <f1-datagrid
  [columns]="columns"
  [styleWidthProp]="styleWidthProp"
  [datajsonUrl]="datajsonUrl"
  [allColumns]="allColumns"
  scrollh="true"
></f1-datagrid> -->
</div>
</div>