import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class EndpointFactory {
  static readonly apiVersion: string = '1.0.0';
  private taskPauser: Subject<any>;
  private isRefreshingLogin: boolean;
  private accessToken: string;

  constructor(
    protected http: HttpClient,
    protected configurations: ConfigurationService,
    private injector: Injector
  ) {}

  private get loginUrl() {
    return this.configurations.baseUrl + this.configurations.loginUrl;
  }

  protected getRequestHeaders(): {
    headers: HttpHeaders;
  } {
    let headers = null;
    const itemStr = JSON.parse(localStorage.getItem('loggedInUser'));
    if (!itemStr) {
      this.accessToken = null;
    } else {
      const item = itemStr;
      this.accessToken = item.tokenGeneration.accessToken;

      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain',
        Authorization: 'Bearer ' + this.accessToken
      });
    }
    return { headers };
  }

  protected getWithoutAuthRequestHeaders(): {
    headers: HttpHeaders;
  } {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return { headers };
  }

  protected handleError(error, continuation: () => Observable<any>) {
    if (error.status === 401) {
      if (this.isRefreshingLogin) {
        return this.pauseTask(continuation);
      }

      this.isRefreshingLogin = true;
    }

    if (
      error.url &&
      error.url.toLowerCase().includes(this.loginUrl.toLowerCase())
    ) {
      return throwError(
        error.error && error.error.error_description
          ? `session expired (${error.error.error_description})`
          : 'session expired'
      );
    } else {
      return throwError(error);
    }
  }

  private pauseTask(continuation: () => Observable<any>) {
    if (!this.taskPauser) {
      this.taskPauser = new Subject();
    }

    return this.taskPauser.pipe(
      switchMap(continueOp => {
        return continueOp ? continuation() : throwError('session expired');
      })
    );
  }

  private resumeTasks(continueOp: boolean) {
    setTimeout(() => {
      if (this.taskPauser) {
        this.taskPauser.next(continueOp);
        this.taskPauser.complete();
        this.taskPauser = null;
      }
    });
  }
}
