<footer>
  <div class="row">
   <div class="col-sm-12">

      Lorem Ipsum is simply dummy text of the printing and typesetting industry
      <p><strong>Call: 000-000-0000 | Mail: operations@aureatetech.com</strong></p>
      <p class="copy">© Copyright 2021 aureatetech.com | All Rights reserved</p>
   </div>
  </div>
</footer>
