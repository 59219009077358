import { Injectable, Injector } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, Subscription, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { CommonEndpointService } from './common-endpoint.service';
import { ConfigurationService } from './configuration.service';
import { Program } from 'src/app/models/program.model';

import {
  ClassPreferModel,
  CourseAdapter
} from 'src/app/models/classPrefer.model';
import { environment } from 'src/environments/environment';
import { EndpointFactory } from './endpoint-factory.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramService extends EndpointFactory {
  appheaders;
  httpOptions;

  constructor(
    http: HttpClient,
    private serviceEndpoint: CommonEndpointService,
    configurations: ConfigurationService,
    private authService: AuthService,
    injector: Injector
  ) {
    super(http, configurations, injector);
    this.appheaders = this.getRequestHeaders();
    this.httpOptions = this.appheaders;
  }


 saveApplication<T>(programDetails: object | string): Observable<any> {
    const formData = programDetails || {};
    return this.http.post<Program>(this.configurations.getSaveProgramDetailsUrl, formData).pipe(
      map(data => {
        
        return data;
      })
    );
  }
  getApplicationsList(): Observable<any[]> {
     let params = new HttpParams();
   
    return this.http
      .get(this.configurations.getSaveProgramDetailsListingUrl, this.httpOptions)
      .pipe(
        map((res: any) => res),
        catchError(error => {
          return throwError('Something went wrong !');
        })
      );
  }
}