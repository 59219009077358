// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { env } from 'process';

export let serverAddress: any;

if (window.location.host === 'localhost:4200') {
  serverAddress = 'actual server address';
} else {
  serverAddress = '';
}

export const environment = {
  production: false,
  baseUrl: 'https://ssoservice.fulcrumone.net/api', // Change this to the address of your backend API if different from frontend address
  loginUrl: '/login',
  API_URL: 'https://ssoservice.fulcrumone.net/',
  API_URL1: 'https://ssoservice.fulcrumone.net/',
  
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
