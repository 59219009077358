import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProgramService } from 'src/app/services/program-service';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator}from '@angular/material/paginator';
import {MatSort}from '@angular/material/sort';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
import {ApplicationService} from 'src/app/services/applicationService';
import { AuthService } from 'src/app/services/auth.service';
export interface Program {
 
  name:string;
  birthdate:string;
  email:string;
  
}
const ELEMENT_DATA: Program[] = [{name: 'qqq', 
 birthdate:'10-1-1990',
 email:'abhinav@gmail.com'
 }];

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {
  displayedColumns = ['name','birthdate','email','action'];
  dataSource: MatTableDataSource<Program>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  formObj = new FormGroup({});
  formModel = {};
  resetform: boolean = false;
  isAdmin:boolean;
  constructor(
   
    private applicationService: ApplicationService,
    private toaster: ToasterService,
    private router: Router,
    private authService : AuthService,
    
  ) {
    this.dataSource = new MatTableDataSource();
   
  }
  fields = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-3',
          key: 'usgprogram',
          type: 'select',
          templateOptions: {
            label: 'Usg Program',
            placeholder: 'Placeholder',
           
            required: true,
            options: [
              { value: "Adult Day Care", label: 'Adult Day Care' },
              { value: "Alarm and Alarm Installers", label: 'Alarm and Alarm Installers'  },
              { value: "Billiards and Pool Halls", label: 'Billiards and Pool Halls'  },
              
            ],
          },
        },
        {
          className: 'col-3',
          type: 'input',
          key: 'versionNo',
          templateOptions: {
            label: 'Version No',
          },
          
        },
        {
          className: 'col-3',
          type: 'input',
          key: 'code',
          templateOptions: {
            label: 'Code',
          },
          
        },
        {
          className: 'col-3',
          key: 'effectiveDate',
      type: 'datepicker',
      templateOptions: {
        label: 'Effective Date',
        placeholder: 'Placeholder',
        startAt:'',
          minDate:new Date("06/14/2021"),
          maxDate:'',
       
        required: true,
      },
          
        },
      ],
    },
    
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
            className: 'col-3',
            key: 'type',
            type: 'select',
            templateOptions: {
              label: 'Type',
              placeholder: 'Placeholder',
             
              required: true,
              options: [
                { value: 1, label: 'General Liability' },
                { value: 2, label: 'Property'  },
                
              ],
            },
          },
        {
          className: 'col-9',
          type: 'input',
          key: 'description',
          templateOptions: {
            label: 'Description',
          },
        },
        
      ],
    },
    
      ];
  //   }
  // ];


  ngOnInit(): void {
    const userdata = JSON.parse(localStorage.getItem('loggedInUser'));
   
    this.getProgramList();
    this.isAdmin = this.authService.isLoggedInUserAdmin;
    if(!this.isAdmin){
      this.router.navigateByUrl('/dashboard');
      this.toaster.error("You are not having enough access");
    }
  
    }
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    getProgramList(): void {
      this.applicationService.getApplicationsList().subscribe(
        data=>{
          if(data.length!==0){
          this.dataSource = new MatTableDataSource(data);
          }
        },
        error=>{}
      );
      
     }
    isUserLoggedIn = true;
 
  gotoProgramListing(){
    this.router.navigateByUrl('/program-listing');
  }
  sendNotificationToWf(e){
    console.log(e);
    this.applicationService.sendDecisionToWF<boolean>(e).subscribe(
      data => {
        
        console.log("data>>",data);
        this.toaster.success(data.Responsemessage);
        //this.getProgramList();
      },
      error =>{
        this.toaster.error("Something Went wrong");
      },
      () => {}
      );

  }
  }
  

