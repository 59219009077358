<div theme theme id="app_container" class="clearfix">
  <app-header *ngIf="isUserLoggedIn"></app-header>

<div class="clearfix"></div>


<section>
  <!-- sidebar code here -->
   <div>
    <mat-sidenav-container class="example-container" autosize>
    <app-side-navigation *ngIf="isUserLoggedIn"></app-side-navigation>
    
    <div >
      <router-outlet></router-outlet>
    </div>
   <!-- <app-right-side-navigation *ngIf="isUserLoggedIn"></app-right-side-navigation> -->
  </mat-sidenav-container>
 
  </div>  
</section>

<div class="clearfix"></div>



<div *ngIf="isUserLoggedIn">

<f1-footer  backGroundBodyColor="#c00" class="ftDiv" footerContent="copyright @fulcrumOne"></f1-footer>
</div>
</div>