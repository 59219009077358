import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator}from '@angular/material/paginator';
import {MatSort}from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import {Program} from 'src/app/models/program.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-library',
  templateUrl: './user-library.component.html',
  styleUrls: ['./user-library.component.scss']
})
export class UserLibraryComponent implements OnInit {
  isAdmin:boolean;
  displayedColumns = ['id', 'name', 'emailId', 'role','actions'];
    dataSource: MatTableDataSource<User>;
  
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
  constructor(  private toaster: ToasterService,
    private router: Router,) {
       // Assign the data to the data source for the table to render
       this.dataSource = new MatTableDataSource(User_DATA);
     }

  ngOnInit(): void {
    const userdata = JSON.parse(localStorage.getItem('loggedInUser'));
    if (userdata.roleID === '2') {
      //isAdmin
      localStorage.setItem('isAdmin', "true");
      localStorage.setItem('isCarrier', "false");
     
      this.isAdmin = true;
    
    } else {
      localStorage.setItem('isCarrier', "true");
      localStorage.setItem('isAdmin', "false");
      this.isAdmin = false;
      this.toaster.error("You are not having enough access");
      this.router.navigateByUrl('/dashboard');
    }
   
    }
    
  
   
  
    /**
     * Set the paginator and sort after the view init since this component will
     * be able to query its view for the initialized paginator and sort.
     */
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    }
    deleteRecord(e){ this.toaster.error("Functionality is not implemented");}
    editRecord(e){ this.toaster.error("Functionality is not implemented");}
  }
  



export interface User {
  id:number;
  name: string;
  emailId: string;
  status: boolean;
  role: string;
  
}

const User_DATA: User[] = [
  { id:1, emailId: "anushree.kaple@fulcrumdigital.com", name: 'Anushree Kaple', status: true, role: 'Carrier' },
  { id:2, emailId: "chetan.kerhalkar@fulcrumdigital.com", name: 'Chetan Khelarkar', status: true, role: 'Carrier' },
  { id:3, emailId: "kalyan.gopalakrishnan@fulcrumdigital.com", name: 'Kalyan GopalKrishnan', status: true, role: 'Administrator' },
  { id:4, emailId: "nikhita.kamath@fulcrumdigital.com", name: 'Nikhita Kamath', status: true, role: 'Administrator' },
 
 
 
];
