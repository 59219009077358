<div class="imgDiv"></div>
<div class="forgotpwdPage">
  <f1-forgotpassword
    submitButtonLable="Forgot Password"
    [fields]="Fields"
    [title]="title"
    (formSubmit)="formSubmit($event)"
  ></f1-forgotpassword>
  <button
    type="button"
    class="btn btn-default btnReset"
    (click)="clearform($event)"
  >
    X
  </button>
</div>
