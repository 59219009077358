<div class="dashboardPage wrapper ratingDiv">
    <div class="row">
        <div class="col-md-2">test</div>
        
        <div class="col-md-10">
            <div class="titleDiv">
				<div class="profileList"><span class="num">+4</span><mat-icon>account_circle</mat-icon> <mat-icon>account_circle</mat-icon> <mat-icon>account_circle</mat-icon><div class="clearfix"></div></div>
                <h2>User Library <mat-icon>info</mat-icon></h2>
            </div>

	<div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
  </div>
  
  <div class="example-container mat-elevation-z8">
  
    <mat-table [dataSource]="dataSource" matSort>
  
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
      </ng-container>
  
      <!-- Progress Column -->
      <ng-container matColumnDef="emailId">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email Id </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.emailId}} </mat-cell>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
      </ng-container>
  
      <!-- Color Column -->
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Role </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.role}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button (click)="editRecord(row.id)" ><mat-icon>edit</mat-icon></button>
          <button (click)="deleteRecord(row.id)" ><mat-icon>delete</mat-icon></button>
         
        </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  </div>
  </div>