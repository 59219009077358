import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login-service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['session-timeout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SessionTimeoutDialogComponent implements OnInit {
  message = '';
  multiplemessages = [];

  constructor(
    public dialogRef: MatDialogRef<SessionTimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private loginService: LoginService,
    private router: Router
  ) {
  }

  ngOnInit(): void {}

  logout(): void {
    this.dialogRef.close('no');
    this.authService.clearAllUserSessionStorages();
  }

  continue(): void {
    this.dialogRef.close('yes');

    const obj = {
      token: this.authService.getCurrentUserRefreshToken(),
      userEmail: this.authService.getCurrentUserEmail()
    };

    this.loginService.refreshToken(obj).subscribe(
      responsedata => {
        let storage = JSON.parse(localStorage.getItem('loggedInUser'));

        if (
          responsedata.accessToken !== null &&
          responsedata.refreshToken !== null &&
          responsedata.expiration !== null
        ) {
          storage.tokenGeneration.accessToken = responsedata.accessToken;
          storage.tokenGeneration.refreshToken = responsedata.refreshToken;
          storage.tokenGeneration.expiration = responsedata.expiration;

          localStorage.setItem('loggedInUser', JSON.stringify(storage));
        }
      },
      error => {
      }
    );
  }
}
