import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SessionTimeoutDialogComponent } from '../components/session-timeout-dialog/session-timeout-dialog.component';

@Injectable()
export class ConfirmSessionDialogService {
  constructor(private dialog: MatDialog) {}

  dialogRef: MatDialogRef<SessionTimeoutDialogComponent>;

  public open(options): void {
    this.dialogRef = this.dialog.open(SessionTimeoutDialogComponent, {
      disableClose: true,
      data: { serviceObj: this }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map(res => {
        return res;
      })
    );
  }
}
