import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(public snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, className: string): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: [className],
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }

  success(message: string): void {
    this.openSnackBar(message, 'X', 'success-toaster');
  }

  error(message: string): void {
    this.openSnackBar(message, 'X', 'error-toaster');
  }

  warning(message: string): void {
    this.openSnackBar(message, 'X', 'warning-toaster');
  }
}
